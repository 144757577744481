//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';
import sourceCard from './cards/sourceCard.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: { Filters, Modal, sourceCard },

  data() {
    return {
      editingRate: {
        show: false,
        data: {},
        isCreating: false,
      },
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersRates', ['data']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradersRates', {
      loadDataAction: 'loadData',
      editRatesAction: 'editRates',
      deleteRateAction: 'deleteRate',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    openModalEditRate(row) {
      this.editingRate.show = true;
      this.editingRate.data = { ...row };
      this.editingRate.isCreating = false;
    },

    openCreateRateModal() {
      this.editingRate.show = true;
      this.editingRate.data = {};
      this.editingRate.isCreating = true;
    },

    applyEdit({ filteredInput }) {
      const { isCreating } = this.editingRate;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create Rate?'
          : 'Are you sure you want to update Rate?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            const data = {
              ...filteredInput,
              fixedRate: filteredInput?.fixedRate === 0 ? undefined : filteredInput.fixedRate,
            };
            await this.editRatesAction(data);
            this.editingRate.show = false;
            await this.loadDataAction({});
            if (isCreating) {
              this.setSuccessNotification('Rate created');
            } else {
              this.setSuccessNotification('Rate edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    deleteRate(input) {
      this.confirmAction({
        title: 'Are you sure you want to delete rate?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.deleteRateAction(input);
            await this.loadDataAction({});
            this.setSuccessNotification('Rate deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    applyFilters() {
      this.loadData();
    },
  },
};
